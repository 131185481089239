import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from "antd";
import basicStyle from "../styles/constants.js";

function Education() {
    const { rowStyle, colStyle, gutter } = basicStyle;
    return (
        <div className='top-section'>
            <Row style={rowStyle} gutter={gutter} justify='center'>
                <Col md={11} xs={24} style={colStyle}>
                    <div className='section'>
                        <h2 className='title-1'>University of California, Irvine</h2>
                        <h3 className='title-2'>Irvine, CA</h3>
                        <p className='text'>Bachelor of Science, Computer Science</p>
                        <p className='text' style={{ marginTop: '-10px', marginBottom: '0' }}>2020</p>
                    </div>
                </Col>

                <Col md={1} xs={24} style={colStyle}></Col>

                <Col md={11} xs={24} style={colStyle}>
                    <div className='section'>
                        <h2 className='title-1'>Saddleback College</h2>
                        <h3 className='title-2'>Mission Viejo, CA</h3>
                        <p className='text'>Associate of Science, Computer Science</p>
                        <p className='text' style={{ marginTop: '-10px', marginBottom: '0' }}>2018</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Education;