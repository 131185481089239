import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from "antd";
import basicStyle from "../styles/constants.js";

import {
    SiJavascript, SiHtml5, SiCss3, SiReact, SiBootstrap,
    SiNodedotjs, SiGraphql, SiMysql, SiMongodb, SiSequelize,
    SiPython, SiAmazonaws, SiJenkins, SiJest, SiJunit5, SiRubyonrails
} from 'react-icons/si';

import { TbBrandCpp, TbBrandKotlin } from 'react-icons/tb';
import { FaJava } from 'react-icons/fa';
import { BiLogoGithub, BiLogoGit } from 'react-icons/bi';
import { GrMysql } from 'react-icons/gr'

function Skills() {
    const { rowStyle, colStyle, gutter } = basicStyle;
    return (

        <div className='top-section'>
            <div className='section'>

                <h2 className='title-1'>Web Development:</h2>
                <div className='skills'>
                    <div className='skill'>
                        <span className='skill-icon'><SiJavascript /></span>
                        <span className='skill-text'>JavaScript</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiHtml5 /></span>
                        <span className='skill-text'>HTML5</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiCss3 /></span>
                        <span className='skill-text'>CSS3</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiReact /></span>
                        <span className='skill-text'>ReactJs</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiBootstrap /></span>
                        <span className='skill-text'>Bootstrap</span>
                    </div>
                </div>
            </div>

            <div style={{ height: '30px' }}></div>

            <div className='section'>
                <h2 className='title-1'>Back-end Development & Databases:</h2>
                <div className='skills'>
                    <div className='skill'>
                        <span className='skill-icon'><SiNodedotjs /></span>
                        <span className='skill-text'>NodeJS</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiRubyonrails /></span>
                        <span className='skill-text'>Ruby on Rails</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><GrMysql /></span>
                        <span className='skill-text'>MySQL</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiMongodb /></span>
                        <span className='skill-text'>MongoDB</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiGraphql /></span>
                        <span className='skill-text'>GraphQL</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiSequelize /></span>
                        <span className='skill-text'>Sequelize</span>
                    </div>
                </div>
            </div>

            <div style={{ height: '30px' }}></div>

            <div className='section'>
                <h2 className='title-1'>Programming Languages:</h2>
                <div className='skills'>
                    <div className='skill'>
                        <span className='skill-icon'><TbBrandCpp /></span>
                        <span className='skill-text'>C++</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><FaJava /></span>
                        <span className='skill-text'>Java</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiPython /></span>
                        <span className='skill-text'>Python</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><TbBrandKotlin /></span>
                        <span className='skill-text'>Kotlin</span>
                    </div>
                </div>
            </div>

            <div style={{ height: '30px' }}></div>

            <div className='section'>
                <h2 className='title-1'>Cloud, DevOps, and Testing:</h2>
                <div className='skills'>
                    <div className='skill'>
                        <span className='skill-icon'><SiAmazonaws /></span>
                        <span className='skill-text'>AWS</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><BiLogoGit /></span>
                        <span className='skill-text'>Git</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiJenkins /></span>
                        <span className='skill-text'>Jenkins</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiJest /></span>
                        <span className='skill-text'>Jest</span>
                    </div>
                    <div className='skill'>
                        <span className='skill-icon'><SiJunit5 /></span>
                        <span className='skill-text'>JUnit 5</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skills;