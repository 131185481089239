export const grid = 8;

export const borderRadius = 2;

const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};
const colStyle = {
  marginBottom: '16px',
};
const gutter = 16;

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
};
export default basicStyle;
