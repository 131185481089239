import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from "antd";
import basicStyle from "../styles/constants.js";
import Divider from '@mui/material/Divider';

function Projects() {
    const { rowStyle, colStyle, gutter } = basicStyle;
    return (
        <div className='top-section'>
            <Row style={rowStyle} gutter={gutter} justify='center'>
                <Col md={11} xs={24} style={colStyle}>
                    <div className='section' style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                        <h2 className='title-1'>Portfolio Website (current website)</h2>
                        <p className="text" style={{ marginTop: '-10px' }}>Dec 2023</p>
                        <Divider style={{ marginBottom: '10px' }} />
                        <div className="text" style={{ paddingRight: '20px' }}>
                            <ul>
                                <li>
                                    Designed and developed a professional portfolio website to showcase
                                    my software engineering projects, skills, and experience.
                                </li>
                                <li>
                                    Implemented interactive features such as project galleries and contact form.
                                </li>
                            </ul>
                        </div>
                        <div className="job-skills" style={{ marginTop: 'auto' }}>
                            <Divider style={{ marginBottom: '10px' }} />
                            <button>JavaScript</button>
                            <button>React</button>
                            <button>HTML</button>
                            <button>CSS</button>
                        </div>
                    </div>
                </Col>

                <Col md={1} xs={24} style={colStyle}></Col>

                <Col md={11} xs={24} style={colStyle}>
                    <div className='section'>
                        <h2 className='title-1'>Database Viewer Website</h2>
                        <p className="text" style={{ marginTop: '-10px' }}>Oct 2021 - Mar 2022</p>
                        <Divider style={{ marginBottom: '10px' }} />
                        <div className="text" style={{ paddingRight: '20px' }}>
                            <ul>
                                <li>
                                    Developed a user-friendly website enabling secure login,
                                    product browsing, and real-time updates for product details.
                                </li>
                                <li>
                                    Implemented user authentication through Okta, integrated
                                    product search functionality, and provided the ability to modify the database directly within the platform.
                                </li>
                            </ul>
                        </div>
                        <div className="job-skills" style={{ marginTop: 'auto' }}>
                            <Divider style={{ marginBottom: '10px' }} />
                            <button>JavaScript</button>
                            <button>React</button>
                            <button>Nodejs</button>
                            <button>MySQL</button>
                            <button>REST API</button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row style={{ ...rowStyle, marginTop: '25px' }} gutter={gutter} justify='center'>
                <Col md={11} xs={24} style={colStyle}>
                    <div className='section' style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                        <h2 className='title-1'>IRL-IPG</h2>
                        <p className="text" style={{ marginTop: '-10px' }}>Aug 2020</p>
                        <Divider style={{ marginBottom: '10px' }} />
                        <div className="text" style={{ paddingRight: '20px' }}>
                            <ul>
                                <li>
                                    Designed and implemented a web application enabling users to track daily goals
                                    and activities, earn points and coins for completion, level up, and make in-app
                                    purchases using earned coins.
                                </li>
                            </ul>
                        </div>
                        <div className="job-skills" style={{ marginTop: 'auto' }}>
                            <Divider style={{ marginBottom: '10px' }} />
                            <button>JavaScript</button>
                            <button>React</button>
                            <button>Java</button>
                            <button>MySQL</button>
                        </div>
                    </div>
                </Col>

                <Col md={1} xs={24} style={colStyle}></Col>

                <Col md={11} xs={24} style={colStyle}>
                    <div className='section' style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                        <h2 className='title-1'>Search Engine</h2>
                        <p className="text" style={{ marginTop: '-10px' }}>Apr 2019</p>
                        <Divider style={{ marginBottom: '10px' }} />
                        <div className="text" style={{ paddingRight: '20px' }}>
                            <ul>
                                <li>
                                    Developed a Python-based search engine to crawl the web, providing the top 10
                                    relevant URLs and the count of URLs matching the query.
                                </li>
                            </ul>
                        </div>
                        <div className="job-skills" style={{ marginTop: 'auto' }}>
                            <Divider style={{ marginBottom: '10px' }} />
                            <button>Python</button>
                            <button>MongoDB</button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row style={{ ...rowStyle, marginTop: '25px' }} gutter={gutter} justify='center'>
                <Col md={11} xs={24} style={colStyle}>
                    <div className='section' style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                        <h2 className='title-1'>Let’s Play NFL Football</h2>
                        <p className="text" style={{ marginTop: '-10px' }}>Sep 2018</p>
                        <Divider style={{ marginBottom: '10px' }} />
                        <div className="text" style={{ paddingRight: '20px' }}>
                            <ul>
                                <li>
                                    Implemented an application assisting users in selecting the best stadium for each football team,
                                    purchasing souvenirs, tickets, and scheduling matches.
                                </li>
                            </ul>
                        </div>
                        <div className="job-skills" style={{ marginTop: 'auto' }}>
                            <Divider style={{ marginBottom: '10px' }} />
                            <button>C++</button>
                            <button>MySQL</button>
                        </div>
                    </div>
                </Col>

                <Col md={1} xs={24} style={colStyle}></Col>

                <Col md={11} xs={24} style={colStyle}>
                </Col>
            </Row>
        </div>


    );
}

export default Projects;