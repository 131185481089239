import React, { useEffect, useRef, useState } from 'react';
import cover from "../images/cover1.jpg";
import cover2 from "../images/cover2.jpeg";
import cover3 from "../images/cover3.jpeg";
import About from "./About.js";
import Experience from "./Experience.js";
import Projects from "./Projects.js";
import Skills from "./Skills.js";
import Education from "./Education.js";
import Contact from "./Contact.js";
import { NavLink } from 'react-router-dom'
import { Link, DirectLink, div, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import { MdWorkHistory, MdAccountCircle, MdEmail } from 'react-icons/md';
import { RiCodeBoxFill } from 'react-icons/ri';
import { FaCode, FaCog, FaGraduationCap } from 'react-icons/fa';

const options = {
  duration: 1500,
  smooth: true,
}

function HomePage() {
  const [issticky, setSticky] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isResponsive, setIsResponsive] = useState(false);

  const handleMenuToggle = () => {
    setIsResponsive(!isResponsive);
  };

  const navbarRef = useRef(null);
  const coverRef = useRef(null);

  const handleScroll = () => {
    if (coverRef.current) {
      setSticky(window.scrollY > coverRef.current.clientHeight);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const delay = 4000;

    if (!animationPlayed) {
      setTimeout(() => {
        setAnimationPlayed(true);
      }, delay);
    }
  }, [animationPlayed]);


  return (
    <div>
      <div ref={coverRef} className="main-wrapper">
        <div className="main-cover" style={{ backgroundImage: `url(${cover})` }}></div>
        <h1 className="main-title">
          <span style={{ fontSize: '6vw', fontWeight: '600' }}>Hedyeh Azimi</span>
          <span style={{ fontSize: '3vw' }}>Full Stack Developer</span>
        </h1>
      </div>

      {screenWidth > 600 ?
        <nav data-issticky={issticky} ref={navbarRef}
          className={`navbar ${issticky ? 'sticky' : ''}`}>
          {/*<a onClick={() => scroll.scrollToTop(options)} className={`nav-a ${issticky ? 'sticky' : ''}`}>TOP</a>*/}
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            About
          </Link>
          <Link
            activeClass="active"
            to="experience"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            Experience
          </Link>
          <Link
            activeClass="active"
            to="projects"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            Projects
          </Link>
          <Link
            activeClass="active"
            to="skills"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            Skills
          </Link>
          <Link
            activeClass="active"
            to="education"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            Education
          </Link>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={1500}
            offset={-100}
            className={`nav-link ${issticky ? 'sticky' : ''} ${animationPlayed ? 'animation-played' : ''}`}
            style={{ fontSize: '20px' }}
          >
            Contact
          </Link>
        </nav>
        :
        <div className={`topnav ${isResponsive ? 'responsive' : ''}`}>
          {/*<a href="#home" class="active">Home</a>
          <a href="#news">News</a>
          <a href="#contact">Contact</a>
          <a href="#about">About</a>
          <a className="icon" onClick={handleMenuToggle}>
            <FaCode />
      </a>*/}
        </div>}


      <div className="content">

        <div name="about">
          <div className="title-div">
            <p> <span><MdAccountCircle /></span>About</p>
          </div>
          <About />
        </div>

        <div name="experience">
          <div className="title-div">
            <p> <span><MdWorkHistory /></span>Experience</p>
          </div>
          <Experience />
        </div>

        <div name="projects">
          <div className="title-div">
            <p> <span><FaCode /></span>Projects</p>
          </div>
          <Projects />
        </div>

        <div name="skills">
          <div className="title-div">
            <p> <span><FaCog /></span>Skills</p>
          </div>
          <Skills />
        </div>

        <div name="education">
          <div className="title-div">
            <p> <span><FaGraduationCap /></span>Education</p>
          </div>
          <Education />
        </div>

        <div name="contact">
          <div className="title-div">
            <p> <span><MdEmail /></span>Contact</p>
          </div>
          <Contact />
        </div>
      </div>

    </div>
  );
}

export default HomePage;