import React, { useEffect, useRef, useState } from 'react';
import shure from '../images/shure.jpeg';
import cerner from '../images/cerner2.jpeg';
import { Row, Col } from 'antd';
import basicStyle from '../styles/constants.js';
import Divider from '@mui/material/Divider';

const { rowStyle, colStyle, gutter } = basicStyle;
function Experience() {
    return (
        <div className="top-section">
            <div className="section">
                <Row style={rowStyle} gutter={gutter} justify="center">
                    <Col md={4} xs={4} style={colStyle}>
                        <img
                            src={shure}
                            alt="shure"
                            className="image"
                            style={{ width: '12vw', maxWidth: '100px' }}
                        />
                    </Col>
                    <Col md={20} xs={20} style={colStyle}>
                        <h2 className="title-1" style={{ marginBottom: '5px' }}>Full Stack Software Developer II</h2>
                        <h3 className="text" style={{ marginBottom: '0' }}>Shure Incorporated</h3>
                        <p className="text" style={{ marginBottom: '0' }}>Aug 2021 - Present</p>
                    </Col>
                </Row>
                <Divider style={{ marginTop: '-4px', marginBottom: '10px' }} />
                <div className="text" style={{ paddingRight: '20px' }}>
                    <ul>
                        <li>
                            Full-stack engineer responsible for designing, developing,
                            testing, and implementing business application systems to meet the
                            needs and objectives of users.
                        </li>
                        <li>
                            Designed and built an advanced scanner application for the Android
                            platform.
                        </li>
                        <li>
                            Developed and maintained APIs to facilitate seamless data exchange
                            between front-end and back-end systems.
                        </li>
                        <li>
                            Managed a new developer, providing mentorship and guidance on
                            technical issues.
                        </li>
                        <li>
                            Collaborated with product owners and other development team
                            members to determine the new features and user stories needed for
                            applications and large development projects.
                        </li>
                        <li>
                            Improved network, system, and data availability and integrity by
                            30% through preventive maintenance and upgrades.
                        </li>
                    </ul>
                </div>
                <Divider style={{ marginBottom: '10px' }} />
                <div className="job-skills">
                    <button>JavaScript</button>
                    <button>React</button>
                    <button>Nodejs</button>
                    <button>Kotlin</button>
                    <button>MySQL</button>
                    <button>MongoDB</button>
                </div>
            </div>

            <div style={{ height: '30px' }}></div>

            <div className="section">
                <Row style={rowStyle} gutter={gutter} justify="center">
                    <Col md={4} xs={4} style={colStyle}>
                        <img
                            src={cerner}
                            alt="cerner"
                            className="image"
                            style={{ width: '12vw', maxWidth: '100px' }}
                        />
                    </Col>

                    <Col md={20} xs={20} style={colStyle}>
                        <h2 className="title-1" style={{ marginBottom: '5px' }}>Full Stack Software Developer</h2>
                        <h3 className="text" style={{ marginBottom: '0' }}>Cerner Corporation</h3>
                        <p className="text" style={{ marginBottom: '0' }}>Aug 2020 - Aug 2021</p>
                    </Col>
                </Row>
                <Divider style={{ marginTop: '-4px', marginBottom: '10px' }} />
                <div className="text" style={{ paddingRight: '20px' }}>
                    <ul>
                        <li>
                            Designed and implemented web applications for the healthcare
                            industry using JavaScript, React, and Ruby on Rails.
                        </li>
                        <li>
                            Reduced the number of technical support inquiries by 20% by
                            creating documentation to address common technical issues.
                        </li>
                        <li>
                            Wrote and optimized test cases to maximize the success of manual
                            software testing with consistent, thorough approaches.
                        </li>
                    </ul>
                </div>
                <Divider style={{ marginBottom: '10px' }} />
                <div className="job-skills">
                    <button>JavaScript</button>
                    <button>React</button>
                    <button>Ruby on Rails</button>
                    <button>MySQL</button>
                    <button>Python</button>
                </div>
            </div>
        </div>
    );
}

export default Experience;
