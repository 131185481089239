import React, { useState } from 'react';
import { Row, Col } from 'antd';
import basicStyle from '../styles/constants.js';
import TextField from '@mui/material/TextField';
import connect from '../images/connect-icon.png';
import emailjs from '@emailjs/browser';
import { BsLinkedin, BsGithub } from 'react-icons/bs';

const { rowStyle, colStyle, gutter } = basicStyle;

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [showError, setShowError] = useState(false);
    const [showMsgError, setShowMsgError] = useState(false);
    const [showMsgSuccess, setShowMsgSuccess] = useState(false);

    const emailjsServiceId = 'contact_service';
    const emailjsTemplateId = 'contact_form';
    const emailjsPublicKeyId = 'p7MfyzTs7kL8X4mQv';

    const handleClearFlags = () => {
        setShowError(false);
        setShowMsgError(false);
        setShowMsgSuccess(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (name && email && message) {
            const msgInfo = {
                name: name,
                email: email,
                message: message,
            }

            emailjs.send(emailjsServiceId, emailjsTemplateId, msgInfo, emailjsPublicKeyId)
                .then((result) => {
                    console.log('Email success');
                    //console.log(result.text);
                    setShowError(false);
                    setShowMsgError(false);
                    setShowMsgSuccess(true);
                    setName('');
                    setEmail('');
                    setMessage('');
                }, (error) => {
                    console.log('Email fail');
                    ///console.log(error.text);
                    setShowError(false);
                    setShowMsgSuccess(false);
                    setShowMsgError(true);
                });

        } else {
            setShowError(true);
        }
    };

    return (
        <div className="top-section">
            <div className="section" style={{ textAlign: 'center' }}>
                <Row style={rowStyle} gutter={gutter} justify="center">
                    <Col md={6} xs={24} style={{ textAlign: 'left' }}>
                        <div className="connect-container">
                            <img
                                src={connect}
                                alt="connect"
                                className="connect-icon"
                            />
                            <div className="social-icons">
                                <a href="https://www.linkedin.com/in/hedyeh-azimi/" target="_blank" rel="noopener noreferrer">
                                    <div className='social-icon'>
                                        <BsLinkedin style={{ fontSize: '2rem', color: '#0077B5' }} />
                                    </div>
                                </a>

                                <a href="https://github.com/hedyehazimi" target="_blank" rel="noopener noreferrer">
                                    <div className='social-icon'>
                                        <BsGithub style={{ fontSize: '2rem', color: '#333' }} />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>

                    <Col md={16} xs={24} style={colStyle}>
                        <div>
                            <h2 className='connect-header'>Let's Connect!</h2>
                            <p className="error-msg" style={{ visibility: showError ? 'visible' : 'hidden' }}>
                                Please fill out all the fields.
                            </p>
                            <p className="error-msg-2" style={{ color: 'red', display: showMsgError ? 'block' : 'none' }}>
                                Thank you for reaching out! There was a problem sending your message. Please try again
                            </p>
                            <p className="error-msg-2" style={{ color: 'green', display: showMsgSuccess ? 'block' : 'none' }}>
                                Thank you for reaching out! Your message has been successfully sent. I will get back to you as soon as possible.
                            </p>
                            <form onSubmit={handleSubmit}>
                                <Row style={{ ...rowStyle, marginBottom: '15px' }} gutter={gutter} justify="center">
                                    <TextField
                                        className="contact-input"
                                        label="Your Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onFocus={handleClearFlags}
                                    />
                                </Row>

                                <Row style={{ ...rowStyle, marginBottom: '15px' }} gutter={gutter} justify="center">
                                    <TextField
                                        className="contact-input"
                                        label="Your Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={handleClearFlags}
                                    />
                                </Row>

                                <Row style={{ ...rowStyle, marginBottom: '15px' }} gutter={gutter} justify="center">
                                    <TextField
                                        className="contact-input"
                                        label="Your Message"
                                        type="text"
                                        value={message}
                                        multiline
                                        rows={10}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onFocus={handleClearFlags}
                                    />
                                </Row>
                                <Row style={rowStyle} gutter={gutter} justify="center">
                                    <input className="submit-input" type="submit" value="Submit" />
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Contact;
